define("discourse/plugins/discourse-track-open-topic-counts/discourse/initializers/extend-for-track-open-topic-counts", ["exports", "discourse/lib/plugin-api", "discourse/models/category", "discourse/models/nav-item", "discourse-common/lib/get-url", "I18n"], function (_exports, _pluginApi, _category2, _navItem, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // hacky but we will only have one navItem for now
  let currentOpenNavItem;
  var _default = _exports.default = {
    name: "extend-for-track-open-topic-counts",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser) {
        return;
      }

      // For compatibility when plugin is installed alongside https://github.com/discourse-org/discourse-teams-sidebar
      // which happens for one of our enterprise customer. See README.md for more details about this plugin.
      const navItems = _navItem.default.extraNavItemDescriptors;
      if (navItems.find(item => {
        return item.name === "open";
      })) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.5.0", api => {
        api.addNavigationBarItem({
          name: "track_open_topic_counts",
          customFilter: category => category?.custom_fields?.display_open_topic_count_sidebar,
          customHref: category => {
            if (category) {
              return `${(0, _getUrl.default)(category.url)}/l/latest?status=open`;
            }
          },
          forceActive: (_category, _args, router) => {
            const queryParams = router.currentRoute.queryParams;
            return queryParams && Object.keys(queryParams).length === 1 && queryParams["status"] === "open";
          },
          before: "top",
          init: (navItem, category) => {
            if (category) {
              navItem.setProperties({
                category,
                count: category.open_count
              });
            }
            currentOpenNavItem = navItem;
          }
        });
        const messageBus = container.lookup("service:message-bus");
        const site = container.lookup("service:site");
        messageBus.subscribe("/open", msg => {
          const category = _category2.default.findById(msg.category_id);
          if (category) {
            category.set("open_count", msg.count);
            api.refreshUserSidebarCategoriesSectionCounts();
          }
          if (currentOpenNavItem?.category?.id === msg.category_id) {
            currentOpenNavItem.set("count", msg.count);
            currentOpenNavItem.category.set("open_count", msg.count);
          }
        }, site.mb_open_channel_last_id);
        api.registerUserCategorySectionLinkCountable({
          badgeTextFunction: count => {
            if (currentUser.new_new_view_enabled) {
              return count.toString();
            } else {
              return _I18n.default.t("sidebar.open_count", {
                count
              });
            }
          },
          route: "discovery.latestCategory",
          routeQuery: {
            status: "open"
          },
          shouldRegister: _ref => {
            let {
              category
            } = _ref;
            return category?.custom_fields?.display_open_topic_count_sidebar !== "never";
          },
          refreshCountFunction: _ref2 => {
            let {
              category
            } = _ref2;
            return category.open_count;
          },
          prioritizeOverDefaults: _ref3 => {
            let {
              category
            } = _ref3;
            return category?.custom_fields?.display_open_topic_count_sidebar === "always";
          }
        });
      });
    }
  };
});